import { ThemeProvider } from '@emotion/react';
import { useAuthentication } from '@iad-os/react-ghost-auth';
import MenuIcon from '@mui/icons-material/Menu';
import {
  createTheme,
  Divider,
  Drawer,
  List,
  ListItem,
  ListItemText,
  Stack,
  Theme,
  useTheme,
} from '@mui/material';
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import IconButton from '@mui/material/IconButton';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import { deepmerge } from '@mui/utils';
import map from 'lodash.map';
import React, { useEffect, useMemo, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import AvatarButton from '../../components/AvatarButton';
import useMenu, { Menu } from '../../hooks/useMenu';
import useVersion from '../../hooks/useVersion';

const NavBar: React.FC = () => {
  const { changeStatus, isAuthenticated } = useAuthentication();

  const theme = useTheme();

  const [openMenu, setOpenMenu] = useState<boolean>(false);

  const navigate = useNavigate();

  const innerTheme = useMemo(
    () =>
      createTheme(
        deepmerge(theme, {
          ...(theme.palette.mode === 'light' && {
            components: {
              MuiButton: {
                styleOverrides: {
                  root: {
                    color: '#fff',
                  },
                },
              },
            },
          }),
          ...(theme.palette.mode === 'dark' && {
            palette: {
              text: {
                primary: '#f8b133',
              },
            },
          }),
        } as Theme)
      ),
    [theme]
  );

  return (
    <ThemeProvider theme={innerTheme}>
      <MenuDrawer open={openMenu} onClose={() => setOpenMenu(false)} />
      <Box sx={{ flexGrow: 1 }}>
        <AppBar position="sticky">
          <Toolbar>
            <IconButton
              size="large"
              edge="start"
              color="inherit"
              aria-label="menu"
              sx={{ mr: 2 }}
              onClick={() => setOpenMenu(prev => !prev)}
            >
              <MenuIcon />
            </IconButton>
            <Box sx={{ flexGrow: 1 }}>
              <Button
                onClick={() => navigate(isAuthenticated() ? '/protected' : '/')}
              >
                <Typography>Portale operatore</Typography>
              </Button>
            </Box>
            {!isAuthenticated() && (
              <Button color="inherit" onClick={() => changeStatus('LOGIN')}>
                Login
              </Button>
            )}
            {isAuthenticated() && <AvatarButton />}
          </Toolbar>
        </AppBar>
      </Box>
    </ThemeProvider>
  );
};

const MenuDrawer: React.FC<{ open: boolean; onClose: () => void }> = props => {
  const { isAuthenticated } = useAuthentication();
  const navigate = useNavigate();
  const auth = isAuthenticated();

  const [state, setState] = useState<boolean>(false);

  const version = useVersion();

  const menu = useMenu();

  const menuItems = map<
    Menu,
    {
      name?: string;
      link?: string;
      show?: boolean;
      divider: boolean;
      onClick?: () => void;
    }
  >(menu, value => ({
    divider: false,
    name: value.LABEL,
    onClick: () => navigateTo(value.ROUTE),
    show: value.SHOW && value.AUTH === auth,
  })).filter(l => l.show === true);

  useEffect(() => {
    setState(props.open);
  }, [props.open]);

  const toggleDrawer =
    (open: boolean) => (event: React.KeyboardEvent | React.MouseEvent) => {
      if (
        event.type === 'keydown' &&
        ((event as React.KeyboardEvent).key === 'Tab' ||
          (event as React.KeyboardEvent).key === 'Shift')
      ) {
        return;
      }

      setState(open);
    };

  function handleClose() {
    setState(false);
    props.onClose();
  }

  function navigateTo(to: string) {
    handleClose();
    if (to.startsWith('http')) {
      window.open(to, '_blank');
    } else {
      navigate(to, { replace: true });
    }
  }

  return (
    <div>
      <Drawer anchor={'left'} open={state} onClose={handleClose}>
        <Box
          sx={{ width: 250 }}
          role="presentation"
          onClick={toggleDrawer(false)}
          onKeyDown={toggleDrawer(false)}
        >
          <Stack
            direction={'row'}
            display="flex"
            gap={1}
            p={2}
            alignItems={'flex-end'}
          >
            <Typography variant="h4">Menu</Typography>
          </Stack>
          <List>
            {menuItems.map(item => (
              <div key={item.name}>
                {!item.divider && (
                  <ListItem button>
                    <ListItemText
                      primary={item.name}
                      onClick={() => item.onClick && item.onClick()}
                    />
                  </ListItem>
                )}
                {item.divider && <Divider />}
              </div>
            ))}
          </List>
        </Box>
        <Stack
          direction={'row'}
          display="flex"
          gap={1}
          p={2}
          alignItems={'flex-end'}
          height={'100%'}
        >
          <Typography variant="subtitle2">
            Portale operatore v. {version}
          </Typography>
        </Stack>
      </Drawer>
    </div>
  );
};

export default NavBar;
