import { Box } from '@mui/material';
import React from 'react';
import { BounceLoader } from 'react-spinners';

export type LoaderProps = {
  loading: boolean;
};

const Loader: React.FC<LoaderProps> = props => {
  const { loading } = props;
  return (
    <>
      {loading && (
        <Box
          sx={{
            position: 'fixed',
            height: '100%',
            width: '100%',
            backgroundColor: 'rgba(0,0,0, 0.5)',
            alignItems: 'center',
            display: 'flex',
            zIndex: 9999,
            top: 0,
          }}
        >
          <Box
            sx={{ justifyContent: 'center', display: 'flex', width: '100%' }}
          >
            <BounceLoader loading={loading} color={`black`} />
          </Box>
        </Box>
      )}
    </>
  );
};

export default Loader;

/*
position: fixed;
height: 100%;
width: 100%;
background-color: rgba($color: #000000, $alpha: 0.5);
z-index: 9999999;

.center {
  width: 100%;
}
*/
