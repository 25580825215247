import AuthenticationProvider, {
  AuthenticationConfig,
  AutoLogin,
  LogginIn,
} from '@iad-os/react-ghost-auth';
import axios from 'axios';
import 'moment/locale/it';
import React, { useMemo } from 'react';
import { ReactNotifications } from 'react-notifications-component';
import { useNavigate } from 'react-router-dom';
import Loader from './components/Loader';
import AppThemeProvider from './contexts/AppTheme';
import { dispatchErrorMessage } from './core/Notification';
import useInterceptor from './hooks/useInterceptor';
import useVersion from './hooks/useVersion';
import useWellKnown from './hooks/useWellKnown';
import Routes from './routes/Routes';

const App: React.FC = () => {
  const navigate = useNavigate();

  const { loading } = useInterceptor({
    timeout: Number(process.env.REACT_APP_SERVICE_TIMEOUT_MS ?? 10000),
    axiosInstance: axios,
    onError: err => {
      const isAxiosError =
        axios.isAxiosError(err) &&
        (!err.response || err.response?.status >= 500);
      const showNotifcation = isAxiosError || !axios.isAxiosError(err);
      if (showNotifcation) {
        dispatchErrorMessage({ title: 'Errore', message: err.message }, err);
      } else {
        console.error(err);
      }
    },
  });

  const { loading: wellknownLoading, wellKnown } = useWellKnown();

  const version = useVersion();

  const authConfig = useMemo<AuthenticationConfig | undefined>(
    () =>
      wellKnown?.metadata
        ? {
            serviceUrl: wellKnown.metadata.endpoint_api || '',
            providers: {
              [wellKnown.metadata.issuer.name || 'default']: {
                name: wellKnown.metadata.issuer.name || 'default',
                ...wellKnown.metadata.issuer,
              },
            },
          }
        : undefined,
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [wellKnown]
  );

  function handleRoute(route: string) {
    const url = new URL(route);
    navigate(url.pathname, { replace: true });
  }

  return (
    <AppThemeProvider>
      <ReactNotifications />
      <Loader loading={loading} />
      {!wellknownLoading && authConfig && (
        <AuthenticationProvider
          config={authConfig}
          axios={axios}
          onRoute={handleRoute}
          enableLog={!version.endsWith('-ver')}
          overrideRedirectUri
        >
          <Routes />
          <LogginIn>
            <Loader loading={true} />
          </LogginIn>
          <AutoLogin />
        </AuthenticationProvider>
      )}
    </AppThemeProvider>
  );
};

export default App;
