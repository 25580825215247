import { Grid } from '@mui/material';
import map from 'lodash.map';
import { useMemo } from 'react';
import { useNavigate } from 'react-router-dom';
import CardDisplay, { CardDisplayProps } from '../components/CardDisplay';
import useMenu, { Menu } from '../hooks/useMenu';

const Home = () => {
  const navigate = useNavigate();

  const menu = useMenu();

  function goTo(to: string) {
    if (to.startsWith('http')) {
      window.open(to, '_blank');
    } else {
      navigate(to, { replace: true });
    }
  }

  const cards: CardDisplayProps[] = useMemo(
    () =>
      map<Menu, CardDisplayProps>(menu, value => ({
        descriptions: value.TAGS,
        title: value.LABEL,
        onClick: () => goTo(value.ROUTE),
        show: value.SHOW,
      })),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  );

  return (
    <>
      <Grid
        pt={2}
        container
        spacing={{ xs: 2, md: 3 }}
        columns={{
          xs: 1,
          sm: nOfCards(2, cards.length),
          md: nOfCards(2, cards.length),
        }}
      >
        {cards
          .filter(c => c.show === true)
          .map(card => (
            <Grid item key={card.title.trim()} xs={1}>
              <CardDisplay {...card} />
            </Grid>
          ))}
      </Grid>
    </>
  );
};

function nOfCards(max: number, size: number) {
  return size < max ? size : max;
}

export default Home;
