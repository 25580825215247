import { useMemo } from 'react';
import useWellKnown from '../hooks/useWellKnown';

export type Menu = Record<
  | 'VOUCHERS_NOT_USED'
  | 'REQUIRED_TICKETS_SEARCH'
  | 'WIZARD_SEARCH'
  | 'KEY_CLOAK',
  {
    LABEL: string;
    TAGS: string[];
    ROUTE: string;
    SHOW: boolean;
    AUTH: boolean;
  }
>;

const useMenu = () => {
  const wellknown = useWellKnown();

  const menu = useMemo<Menu>(
    () => ({
      REQUIRED_TICKETS_SEARCH: {
        LABEL: 'Ricerca Biglietti rapida',
        TAGS: [
          '- Ricerca Biglietti per codice voucher e/o incontro',
          `- Possibilità di modificare il richiedente di un biglietto ancora non inviato a ticketone`,
        ],
        ROUTE: '/protected/tickets/_search',
        SHOW: true,
        AUTH: true,
      },
      WIZARD_SEARCH: {
        LABEL: 'Ricerca Biglietti guidata',
        TAGS: [
          `- Ricerca Biglietti Guidata step by step partendo dall'anagrafica utente e/o richiedente`,
          `- Possibilità di modificare il richiedente di un biglietto ancora non inviato a ticketone`,
        ],
        ROUTE: '/protected/tickets/_wizard_search',
        SHOW: true,
        AUTH: true,
      },
      VOUCHERS_NOT_USED: {
        LABEL: 'Voucher non utilizzati',
        TAGS: [`- Ricerca Voucher non utilizzati per incontro`],
        ROUTE: '/protected/vouchers',
        SHOW: true,
        AUTH: true,
      },
      KEY_CLOAK: {
        LABEL: 'Gestione Utenti',
        TAGS: [`- Utenti app`, '- Utenti operatori'],
        ROUTE: wellknown.wellKnown.metadata.kc_admin_url,
        SHOW: true,
        AUTH: true,
      },
    }),
    [wellknown]
  );

  return menu;
};

export default useMenu;
