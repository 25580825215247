import axios from 'axios';
import isEmpty from 'lodash.isempty';
import { useEffect, useMemo, useState, useRef } from 'react';
import * as yup from 'yup';

export type WellKnown = {
  domain: string;
  metadata: Metadata;
};

export type Issuer = {
  name?: string;
  authorization_endpoint: string;
  token_endpoint: string;
  client_id: string;
  requested_scopes: string;
  redirect_uri: string;
  end_session_endpoint: string;
  redirect_logout_uri: string;
  pkce: boolean;
};

export type Metadata = {
  name: string;
  endpoint_api: string;
  issuer: Issuer;
  active_debug: boolean;
  icons: Icons;
  kc_admin_url: string;
};

export type Icons = {
  wait: string;
  'request-wait': string;
  download: string;
  error: string;
};

type IssuerShape = {
  [key in keyof WellKnown['metadata']['issuer']]: yup.AnySchema;
};

type IconShape = Record<keyof Metadata['icons'], yup.AnySchema>;

const URL =
  /^(?:([a-z0-9+.-]+):\/\/)(?:\S+(?::\S*)?@)?(?:(?:[1-9]\d?|1\d\d|2[01]\d|22[0-3])(?:\.(?:1?\d{1,2}|2[0-4]\d|25[0-5])){2}(?:\.(?:[1-9]\d?|1\d\d|2[0-4]\d|25[0-4]))|(?:(?:[a-z\u00a1-\uffff0-9]-*)*[a-z\u00a1-\uffff0-9]+)(?:\.(?:[a-z\u00a1-\uffff0-9]-*)*[a-z\u00a1-\uffff0-9]+)*\.?)(?::\d{2,5})?(?:[/?#]\S*)?$/i;
const urlYup = yup.string().matches(URL, 'Enter a valid url');
const wellknownSchema = yup
  .object()
  .shape<{ [key in keyof WellKnown]: yup.AnySchema }>({
    domain: urlYup.required(),
    metadata: yup
      .object()
      .shape<{ [key in keyof WellKnown['metadata']]: yup.AnySchema }>({
        name: yup.string().required(),
        active_debug: yup.boolean(),
        endpoint_api: urlYup.required(),
        kc_admin_url: urlYup.required(),
        icons: yup.object().shape<IconShape>({
          error: urlYup.required(),
          wait: urlYup.required(),
          download: urlYup.required(),
          'request-wait': urlYup.required(),
        }),
        issuer: yup
          .object()
          .shape<IssuerShape>({
            name: yup.string().required(),
            authorization_endpoint: urlYup.required(),
            token_endpoint: urlYup.required(),
            client_id: yup.string().required(),
            requested_scopes: yup.string().required(),
            redirect_uri: urlYup.required(),
            end_session_endpoint: urlYup.required(),
            redirect_logout_uri: urlYup.required(),
            pkce: yup.boolean().required(),
          })
          .required(),
      })
      .required(),
  });

const useWellKnown = () => {
  const [wellKnown, setWellKnown] = useState<WellKnown>(
    WellKnownProfile.getData()
  );
  const effectCalled = useRef<boolean>(false);

  const loading = isEmpty(WellKnownProfile.getData());

  const url = useMemo(
    () => process.env.REACT_APP_WELL_KNOWN || '/.well-known',
    []
  );

  async function createWellKnown(data: WellKnown) {
    try {
      await wellknownSchema.validate(data);
      WellKnownProfile.setData(data);
      setWellKnown(data);
    } catch (err) {
      throw err;
    }
  }

  useEffect(() => {
    async function fetch() {
      try {
        effectCalled.current = true;
        const { data } = await axios.create().get<WellKnown>(url);
        createWellKnown(data);
      } catch (err) {
        console.error('Well known', err);
      }
    }
    if (!WellKnownProfile.getData() && !effectCalled.current) {
      fetch();
    } else {
      setWellKnown(WellKnownProfile.getData());
    }
  }, [url]);

  return { wellKnown, loading };
};

export const WellKnownProfile = (function () {
  let data: WellKnown;

  const getData = function () {
    return data;
  };

  const setData = function (input: WellKnown) {
    data = input;
  };

  return {
    getData,
    setData,
  };
})();

export default useWellKnown;
