import React, { useRef, useState } from 'react';
import { useAuthentication, useUserInfo } from '@iad-os/react-ghost-auth';
import {
  Button,
  ClickAwayListener,
  Divider,
  Grow,
  MenuItem,
  MenuList,
  Paper,
  Popper,
} from '@mui/material';
import Avatar from '../components/Avatar';
import { useNavigate } from 'react-router-dom';

const AvatarButton: React.FC = () => {
  const { logout } = useAuthentication();
  const userInfo = useUserInfo();
  const navigate = useNavigate();

  const anchorRef = useRef<HTMLDivElement>(null);

  const [open, setOpen] = useState<boolean>(false);

  function handleToggle() {
    setOpen(prevOpen => !prevOpen);
  }

  function handleClose(event: Event) {
    if (
      anchorRef.current &&
      anchorRef.current.contains(event.target as HTMLElement)
    ) {
      return;
    }

    setOpen(false);
  }
  return (
    <>
      <div ref={anchorRef}>
        <Button onClick={handleToggle}>
          <Avatar name={userInfo.name} />
        </Button>
      </div>
      <Popper
        open={open}
        anchorEl={anchorRef.current}
        role={undefined}
        transition
        disablePortal
      >
        {({ TransitionProps, placement }: any) => (
          <Grow
            {...TransitionProps}
            style={{
              transformOrigin:
                placement === 'bottom' ? 'center top' : 'center bottom',
            }}
          >
            <Paper>
              <ClickAwayListener onClickAway={handleClose}>
                <MenuList id="split-button-menu">
                  <MenuItem
                    onClick={() => {
                      setOpen(false);
                      navigate('/protected/users');
                    }}
                  >
                    User info
                  </MenuItem>
                  <Divider />
                  <MenuItem
                    onClick={() => {
                      setOpen(false);
                      logout();
                    }}
                  >
                    Logout
                  </MenuItem>
                </MenuList>
              </ClickAwayListener>
            </Paper>
          </Grow>
        )}
      </Popper>
    </>
  );
};

export default AvatarButton;
