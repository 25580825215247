import {
  Card,
  CardActionArea,
  CardContent,
  Stack,
  Typography,
} from '@mui/material';
import React from 'react';

export type CardDisplayProps = {
  title: string;
  descriptions: string[];
  onClick: () => void;
  show: boolean;
};

const CardDisplay: React.FC<CardDisplayProps> = props => {
  const { title, descriptions, onClick } = props;
  return (
    <Card sx={{ height: '100%' }}>
      <CardActionArea onClick={onClick} sx={{ height: '100%' }}>
        <CardContent>
          <Stack direction={'column'} spacing={2}>
            <Typography
              sx={{ fontWeight: 'bold' }}
              gutterBottom
              component="div"
              align="center"
              color="primary"
              variant="h5"
            >
              {title}
            </Typography>
            {descriptions.map((description, i) => (
              <Typography key={i} variant="body1" align="center">
                {description}
              </Typography>
            ))}
          </Stack>
        </CardContent>
      </CardActionArea>
    </Card>
  );
};

export default CardDisplay;
