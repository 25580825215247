import { Avatar as AvatarMUI, SxProps } from '@mui/material';
import React, { useMemo } from 'react';

type AvatarProps = {
  name: string;
  imageSize?: SxProps;
};

const Avatar: React.FC<AvatarProps> = props => {
  const { name, imageSize } = props;

  const url = useMemo(
    () => `https://eu.ui-avatars.com/api/?name=${name.replace(' ', '+')}`,
    [name]
  );
  return <AvatarMUI alt="avatar" src={url} sx={imageSize} />;
};

export default Avatar;
