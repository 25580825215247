import React, { lazy } from 'react';
import { RouteObject, useRoutes } from 'react-router';
import Loadable from '../core/Loadable';
import ProtectedLayout from '../layouts/ProtectedLayout';
import Home from '../pages/Home';

const UserInfo = Loadable(lazy(() => import('../pages/UserInfo')));
const VouchersList = Loadable(lazy(() => import('../pages/VouchersList')));
const RequireTickets = Loadable(
  lazy(() => import('../pages/FastSearchTickets'))
);
const WizardSearchTickets = Loadable(
  lazy(() => import('../pages/WizardSearchTickets'))
);
const TicketDetails = Loadable(lazy(() => import('../pages/TicketDetails')));

const ProtectedRouters: React.FC = () => {
  return useRoutes([routes]);
};

const routes: RouteObject = {
  element: <ProtectedLayout />,
  children: [
    {
      path: '/',
      element: <Home />,
    },
    {
      path: '/users',
      element: <UserInfo />,
    },
    {
      path: '/vouchers',
      element: <VouchersList />,
    },
    {
      path: '/tickets/_search',
      element: <RequireTickets />,
    },
    {
      path: '/tickets/_wizard_search',
      element: <WizardSearchTickets />,
    },
    {
      path: `/tickets/:id`,
      element: <TicketDetails />,
    },
  ],
};

export default ProtectedRouters;
